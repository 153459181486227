.play-button {
  cursor: pointer;
  color: blue !important;
  pointer-events: all !important;
  margin-top: -10px;
}

.cyphercontainer {
  padding-right: 50px;
}
