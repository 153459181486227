.graph-container {
  .controls {
    label {
      margin: 2px 10px 2px 0;
      input,
      select {
        margin-right: 5px;
      }
    }
  }
  &.is-position-absolute {
    position: fixed !important;
    background-color: var(--bulma-body-background-color);
    z-index: 100;
    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;
  }

  .expand-button {
    position: relative;

    > div {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      color: cornflowerblue;
      z-index: 49;
    }
  }

  .sidepanel {
    position: absolute;
    z-index: 9999;
    margin-left: 1%;
    margin-top: 2%;
    //top: 0;
    width: 400px;
    //height: 100vh;
    //background: rgb(93 138 155 / 40%);
    color: inherit;
    //padding: 20px;

    .panel {
      background-color: white;
    }
    .table-wrapper {
      max-height: 400px;
      overflow-y: auto;
      table {
        background: transparent;

        td:nth-child(1) {
          font-weight: bold;
          min-width: 150px;
          word-break: break-word;
        }

        td:nth-child(2) {
          div {
            word-break: break-word;
            //white-space: pre-wrap;
            //max-width: 200px;
          }
        }
      }
    }
    .open-container {
      background-color: dodgerblue;
      display: inline-block;
      color: white;
      padding: 2px;
      border-radius: 50%;
      cursor: pointer;
    }

    .delete-button {
      margin-left: 10px;
    }
    .GraphRelatedLinksList {
      margin: 10px 0;
      padding: 10px 20px;
      background: var(--bulma-body-background-color);
      max-height: 300px;
      overflow-y: auto;
      ul {
        list-style: initial;
      }
      .tag {
        margin: 5px;
      }
    }
  }
}
