.SavedQueriesContainer {
  .scroll {
    max-height: 300px;

    .icon {
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
