.App {
  /*text-align: center;*/
  padding-bottom: 20px;
}
.margin-left {
  margin-left: 10px;
}

.scroll-500 {
  max-height: 500px;
  overflow-y: auto;
}
