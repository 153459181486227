.leftPanel {
  width: 400px;
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  left: 0;

  transition: left 0.4s ease-in-out;
  &.closed {
    left: -330px;

    > * {
      display: none;

      &.open-container {
        display: initial;
      }
    }
  }

  .open-container {
    background-color: dodgerblue;
    display: inline-block;
    position: absolute;
    right: 20px;
    color: white;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
  }
}
